import { useState, useEffect } from 'react';
import Title from './title';
import Flds from './flds';
import Btn from './btn';
import Mess from './mess';
import Info from './info';
import './style.css';
import { type } from 'skin/event';
import api from 'skin/http';

const arr2 = [
	{
		tit: 'IP-адрес',
		val: '192.168.1.1',
	},
	{
		tit: 'Браузер',
		val: 'Информация о браузере',
	},
	{
		tit: 'Дата и время',
		val: new Date().toLocaleString(),
	},
];

const Win = () => {
	let cls = ['skin-win', 'window'];
	cls = cls.join(' ');
	const [mess, setMess] = useState([]);
	const [info, setInfo] = useState([]);
	useEffect(loadInfo, []);

	return (
		<div className={cls}>
			<Title title="Регистрация площадки" />
			<form onSubmit={signup}>
				<Flds />
				<div className='btn-signup'>
					<Btn title="Отмена" click={login} type="button" />
					<Btn title="Зарегистрироваться" type="submit" />
				</div>
			</form>
			<Mess mess={mess} />
			<Info info={info} />
		</div>
	);

	// Запросить ситемную информацию
	function loadInfo() {
		document.getElementsByName('login')[0].focus();
		setInfo(arr2);
	}

	function login() {
		type('login');
	}

	// Регистрация
	function signup(e) {
		const reg = /^[\w]*$/
		const a = []
		e.preventDefault();
		const o = {
			login: document.getElementById('login').value,
			password1: document.getElementById('password1').value,
			password2: document.getElementById('password2').value,
			market: document.getElementById('market').value,
		};
		if (o.password1 !== o.password2) a.push('Пароли должны совпадать!')		
		if ( !reg.test(o.login)) a.push('Логин должен содержать только латинские символы и цифры!')
		if (a.length) return setMess(a)
		// setWait(true);
		api.post('api/auth/signup', o)
			.then((response) => {
				alert(
					'Учетная запись создана!\nДля продолжения авторизируйтесь.'
				);
				// setWait(false);

				setTimeout(() => type('login'), 500);
			})
			.catch((e) => {
				console.log('Error ', e.response?.data);
				let msg = e.response?.data?.message;
				if (!msg) return;
				if (!Array.isArray(msg)) msg = [msg];
				setTimeout(() => setMess(msg), 1000);
				// setWait(false);
			});
	}
};

export default Win;
