import './style.css'
import user from 'skin/store/user';

const Item = props => {
	let cls = [
		'skin-tbl-com-cnt-item',
		'tbl-com-cnt-item'
	]
	cls = cls.join(' ')
	const id = props.data.id
	const title = props.data.title
	const st = {backgroundImage: `url(skin/img/com_${id}.svg)`}
	let ttl = <span className='tbl-head-com-cnt-itm-tit'>{title}</span>
	if(user.short) ttl = null
	return (
		<div className={cls} onClick={() => props.click(id)}>
			<span className='ico' style={st} title={title}></span>
			{ttl}
		</div>
	)
}

export default Item
