import { useEffect, useState } from 'react';
import Error from '../error';
import Label from './lable';
import { dictionary } from 'skin/event';
import './style.css';

const InputBool = ({ s, v, e, setValue }) => {
	const { _id, name, title = '', width = '50px', style = {}, type } = s;

	const dtype = 'code';
	const dcode = 'bool';

	// Значение
	const [val, setVal] = useState(v);
	// Массив ошибок - получаем пропсами из mobx
	const [err, setErr] = useState(e);
	// Индикатор Показывали ошибки или нет
	const [status, setStatus] = useState(false);

	useEffect(() => {
		if (v === null) return setVal('');
		setVal(v ? 'Да' : 'Нет');
	}, [v]);

	useEffect(() => {
		setErr(e);
	}, [e]);

	let img;
	if (dtype) {
		img =
			dtype === 'code'
				? { backgroundImage: 'url("skin/img/dct_code.svg")' }
				: { backgroundImage: 'url("skin/img/dct_str.svg")' };
	}

	let cls = ['skin-input-base', 'input-base'];

	if (err) cls.push('error');
	cls = cls.join(' ');

	return (
		<div className={cls} style={style}>
			<Label label={s?.label} />
			<div className="input">
				<input
					id={_id}
					type={'text'}
					name={name}
					value={val ?? ''}
					disabled={true}
					autoComplete={'false'}
					onFocus={onFocus}
					style={{ width: width }}
					title={title}
				/>
				{/* Иконка */}
				{img ? (
					<div
						className="input-ico"
						style={img}
						onClick={icoClick}
					></div>
				) : null}
			</div>

			{/* Ошибки */}
			{status ? (
				<Error err={err} onClick={() => setStatus(false)} />
			) : null}
		</div>
	);

	// Показываем список ошибок
	function onFocus(e) {
		if (err && !status) {
			setStatus(true);
		}
	}

	function icoClick() {
		if (!dtype) return;
		// Отображаем дешифратор
		setStatus(false);
		setErr(null);

		function setV(v) {
			setValue(name, v === 'Да' ?? 'Нет', type);
		}
		const o = { code: dcode, func: setV };
		dictionary(o);
	}
};

export default InputBool;
