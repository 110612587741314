import { useEffect, useState } from 'react';
import Error from '../error';
import Label from './lable';
import './style.css';

const InputBase = ({ s, v, e, setValue, action }) => {
	const {
		_id,
		name,
		keys,
		off,
		title = '',
		disabled = false,
		width = '180px',
		style = {},
		type = 'type',
		autoComplete = 'off',
	} = s;

	const dtype = s.dictionary?.type;
	const dcode = s.dictionary?.code;

	// Значение
	const [val, setVal] = useState(v);
	// Массив ошибок - получаем пропсами из mobx
	const [err, setErr] = useState(e);
	// Индикатор Показывали ошибки или нет
	const [status, setStatus] = useState(false);

	useEffect(() => {
		// Защита от сброса курсора в конец текста
		if (val !== v) {
			setVal(v);
		}
	}, [v, val]);

	useEffect(() => {
		setErr(e);
	}, [e]);

	let img;
	if (dtype) {
		img =
			dtype === 'code'
				? { backgroundImage: 'url("skin/img/dct_code.svg")' }
				: { backgroundImage: 'url("skin/img/dct_str.svg")' };
	}

	let cls = ['skin-input-base', 'input-base'];

	if (err) cls.push('error');
	cls = cls.join(' ');
	if (off) style.display = 'none';

	return (
		<div className={cls} style={style}>
			<Label label={s?.label} />
			<div className="input" onClick={onFocus}>
				<input
					id={_id}
					type={type}
					name={name}
					value={val}
					disabled={dtype ? true : disabled}
					autoComplete={autoComplete}
					onChange={onChange}
					style={{ width: width }}
					title={title}
				/>
				{/* Иконка */}
				{img ? (
					<div
						className="input-ico"
						style={img}
						onClick={icoClick}
					></div>
				) : null}
			</div>

			{/* Ошибки */}
			{status ? (
				<Error err={err} onClick={() => setStatus(false)} />
			) : null}
		</div>
	);

	function onChange(e) {
		setStatus(false);
		setErr(null);
		setVal(e.target.value);

		setValue(name, e.target.value, type);
	}

	// Показываем список ошибок
	function onFocus(e) {
		if (err && !status) {
			setStatus(true);
		}
	}
	
	function icoClick() {
		if (!dtype) return;
		// Отображаем дешифратор
		setStatus(false);
		setErr(null);

		function setV(v) {
			Array.isArray(v) ? keysVal(v) : setValue(name, v, type);
		}
		const o = {
			type: 'data',
			action: 'dictionary',
			code: dcode,
			func: setV
		}
		action(o)
		function keysVal(v) {
			setValue(name, v[0], type);
			if (!keys) return;
			const key = keys.split(',');
			if (key.length > v.length - 1) return;
			for (let i = 0; i < key.length; i++) {
				const name = key[i].split(':')[0];
				const type = key[i].split(':')[1];
				setValue(name, v[i+1], type);
			}
		}
	}
};

export default InputBase;
