const data = [
	{
		id: "save",
		title: "Сохранить",
	},
	{
		id: "add",
		title: "Добавить",
	},
	{
		id: "delete",
		title: "Удалить",
	},
	{
		id: "action",
		title: "Функционал",
	},
];

export default data;
