import Title from "./title";
// import Command from './command';
import "./style.css";

const Head = (props) => {
	let cls = ["skin-form-head", "form-head"];
	cls = cls.join(" ");
	return (
		<div className={cls}>
			<Title/>
		</div>
	);
};

export default Head;
