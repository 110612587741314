import './style.css'

const Mess = props => {
    if (props.mess.length === 0) return null
    let cls = ['skin-mess', 'clr-t4', 'mess']
    cls = cls.join(' ')
    const stl = {backgroundImage: 'url(skin/img/login_att.svg)'}
    return (
        <ul className={cls}>
            {props.mess.map((el, i) => <li key={i} style={stl}>{el}</li>)}
        </ul>
    )
}

export default Mess