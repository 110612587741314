import {info} from 'skin/event'
import Main from './main'
import './style.css'

const Content = props => {
	let cls = [
		'skin-info-content',
		'info-content'
	]
	cls = cls.join(' ')
	const st = {backgroundImage: 'url(skin/img/close.svg)'}
	return (
		<div className={cls}>
			<h1>Техническая информация</h1>
			<Main data={props.data}/>
			<div className='info-cnt-close' style={st} onClick={close}></div>
		</div>
	)
}
// Закрыть окно
function close() {
	info()
}

export default Content
