import Control from './control';
import Page from './page';
import './style.css';

const Command = ({ action, page, pages, setPage, data }) => {
	let cls = ['skin-cmptbl-command', 'cmptbl-command'];
	cls = cls.join(' ');
	return (
		<div className={cls}>
			<Control action={action} data={data} />
			<Page page={page} pages={pages} action={action} setPage={setPage} />
		</div>
	);
};

export default Command;
