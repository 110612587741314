import Personal from './personal';
import Message from './message';
import Help from './help';
import Logout from './logout';
import Info from './info';
import Balance from './balance';
import './style.css';
import Moment from 'react-moment';
import { useEffect} from 'react';
import user from 'skin/store/user';
import { observer } from 'mobx-react-lite';

const Right = (props) => {
	let cls = ['skin-header-right', 'header-right', 'd-flex jc-end ai-center'];
	cls = cls.join(' ');
	useEffect(() => {
		user.get();
	}, []);
	return (
		<div className={cls}>
			<Balance blc={user.blc} payDate={user.payDate} adm={user.adm}/>
			<Personal avatar={user.avatar} info={[user.id]} />
			<span className="user-name" title={user.name} >{user.name}</span>
			<Logout />
			<span className="btn moment">
				<Moment format="DD.MM.YYYY, HH:mm:ss" interval={1000} title='Текущее время' />
			</span>
			<Message />
			<Help />
			<Info />
		</div>
	);
};

export default observer(Right);
