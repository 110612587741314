import Page from 'skin/form/page';
import './style.css';

const Footer = (props) => {
	let cls = ['skin-form-footer', 'form-footer'];
	cls = cls.join(' ');
	return (
		<div className={cls}>
			<div></div>
			<Page />
		</div>
	);
};

export default Footer;
