import './style.css';

const balance = ({blc, payDate, adm}) => {
	let cls = ['skin-hdr-r-balance', 'hdr-r-balance', 'balance'];
	const st = {}
	if(!adm) st.display = 'none'
	cls = cls.join(' ');
	return (
		<div className={cls} style={st}> 
			<span>Баланс <u>{+blc.toFixed(2)}</u> руб.</span>
			<span>/</span>
			<span>{payDate}</span>
		</div>
	);
}


export default balance
