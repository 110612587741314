import {useContext} from 'react'
import { observer } from "mobx-react-lite";
import {Cnt} from 'skin/context'
import View from "./view";
import List from "./list";
import "./style.css";

const Command = (props) => {
	let cls = ["skin-tbl-head-command", "tbl-head-command"];
	cls = cls.join(" ");
	const func = props.view.func;
	const on = props.view?.on ?? false
	const {code, form, action, view} = useContext(Cnt)
	const arr = props.view?.list ?? []
	const v = getTitle()
	const st = { backgroundImage: "url(skin/img/reload.svg)" };
	return (
		<div className={cls}>
			<span className="ico-r" style={st} onClick={reload}></span>
			<span className="tbl-head-com-name" data-info={code} data-info-title='Таблица'>
				{props.title}
			</span>
			<span className="dlm" data-info={form} data-info-title='Форма'></span>
			<View data={{ view: v, func }} />
			<List data={{ arr, on }} current={view} />
		</div>
	)
	// Определить представление
	function getTitle() {
		if (view === '' || !arr[view]) return '<Не определено>'
		return arr[view]
	}
	// Перечитать таблицу
	function reload() {
		const o = {
			type: 'table',
			action: 'reload'
		}
		action(o)
	}
};

export default observer(Command);
