const data = [
	{
		id: "edit",
		title: "Редатировать",
	},
	{
		id: "view",
		title: "Просмотреть",
	},
	{
		id: "add",
		title: "Добавить",
	},
	{
		id: "delete",
		title: "Удалить",
	},
];

export default data;
