import { status } from 'skin/event/status'
import kindlist from './kind_list'

function table(obj, ms, story, data, d, action) {
	switch (obj.table) {
		case 'tnk_kindList':
			return kindlist(obj, ms, story, data, d, action)
		default:
			return status.action
	}
}
export default table
