const data = {
	menu: {
		img: 'menu',
		name: 'Системное меню'
	},
	table: {
		img: 'table',
		name: 'Системная таблица'
	},
	form: {
		img: 'form',
		name: 'Форма'
	},
	master: {
		img: 'master',
		name: 'Мастер действия'
	},
}

export default data
