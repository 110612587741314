// Действия в функционале
function actionAct(obj, table) {
	switch (obj.action) {
		case 'click':
			table.fncClick(obj.id);
			break;
		case 'close':
			table.func();
			break;
		case 'home':
			table.fncHome();
			break;
		case 'up':
			table.fncUp();
			break;
		default:
			alert('[Функционал] Действие не определено');
	}
}

export default actionAct;
