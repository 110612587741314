import { makeAutoObservable } from "mobx";
import def from "./def";
// import tnk_sensor from "./tnk_sensor";
// import tnk_cooler from "./tnk_cooler";
import tnk_valve from "./tnk_valve";
import tnk_building from "./tnk_building";
import tnk_settingList from "./tnk_settingList";
import tnk_settingWithout from "./tnk_settingWithout";
//import tnk_aggregate from "./tnk_aggregate";
import tnk_setting from './tnk_setting'
import tnk_signal from './tnk_signal'

class Data {
	s = {
		def,
		// tnk_sensor,
		// tnk_cooler,
		tnk_valve,
		tnk_building,
		tnk_settingList,
		tnk_settingWithout,
		//tnk_aggregate,
		tnk_setting,
		tnk_signal
	}
	constructor(props) {
		makeAutoObservable(this)
	}
}

const data = new Data()
export default data
