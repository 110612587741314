// Действия
function dataAct(obj, data, action) {
	const act = obj.action ?? null;
	switch (act) {
		case 'wAdd':
			data.wAdd(obj.code, obj.data);
			break;
		case 'wPing':
			data.wPing(obj.work, obj.pug);
			break;
		case 'wOut':
			data.wOut(obj.work, obj.pug, action);
			break;
		default:
			alert('[Таблица] [Данные] Действие не определено');
	}
}

export default dataAct;
