import Control from "./control";
import Page from "skin/form/page";
import "./style.css";

const Command = (props) => {
	let cls = ["skin-frm-command", "frm-command"];
	cls = cls.join(" ");
	return (
		<div className={cls}>
			<Control />
			<Page />
		</div>
	);
};

export default Command;
