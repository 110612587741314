import Dlm from './dlm';
import './style.css';

const Page = ({ max, item, current, data, action }) => {
	if (max === 0) return null;
	let cls = ['skin-ms-ttl-right-page', 'ms-ttl-right-page'];
	cls = cls.join(' ');
	let cl_ico = ['ico'];
	if (item === current) cl_ico.push('on');
	cl_ico = cl_ico.join(' ');
	let title = data.description ?? '';
	const st = { backgroundImage: 'url(skin/img/ms_page_itm.svg)' };
	title =
		title === '' ? null : (
			<div className="ms-ttl-right-page-title" style={st}>
				{title}
			</div>
		);
	const dlm = item < max ? <Dlm /> : null;
	return (
		<div className={cls}>
			<span className={cl_ico} onClick={() => click(item)}>
				{item + 1}
			</span>
			{dlm}
			{title}
		</div>
	);

	function click(idx) {
		if (idx === current) return;
		const o = {
			type: 'master',
			action: 'selectPg',
			data: idx,
		};
		action(o);
	}
};

export default Page;
