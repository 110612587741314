import Content from './content'
import './style.css'

const Info = props => {
	if (!props.data) return null
	let cls = [
		'skin-info',
		'techinfo'
	]
	cls = cls.join(' ')
	return (
		<div className={cls}>
			<div className='info-shadow'></div>
			<Content data={props.data} />
		</div>
	)
}

export default Info
