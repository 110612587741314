import { useState, useEffect } from "react"

import Title from "./title"
import Flds from "./flds"
import Btn from "./btn"
import Info from "./info"
import Mess from "./mess"
import { type } from "skin/event"
import api from 'skin/http'

import './style.css'

const arr = [
	{
		tit: 'IP-адрес',
		val: '192.168.1.1',
	},
	{
		tit: 'Браузер',
		val: 'Информация о браузере',
	},
	{
		tit: 'Дата и время',
		val: new Date().toLocaleString(),
	},
]

const Win = () => {
    let cls = ['skin-win', 'window']
    cls = cls.join(' ')
    const [info, setInfo] = useState([])
    const [mess, setMess] = useState([])
    useEffect(loadInfo, [])
    return (
        <div className={cls}>
            <Title title="Сброс пароля пользователя"/>
            <form onSubmit={reset}>
                <Flds />
                <div className="btn-reset">
                    <Btn title="Отмена" click={login} type="button" />
                    <Btn title="Сбросить пароль" type="submit" />
                </div>
            </form>
            <Mess mess={mess} />
            <Info info={info} />
        </div>
    )
    
    // Запросить ситемную информацию
    function loadInfo() {
        document.getElementsByName('login')[0].focus()
        setInfo(arr)
    }

    function login() {
        type('login')
    }

    // Сброс пароля
    function reset(e) {
        const reg = /^[\w]*$/
        const a = []
        e.preventDefault()
        const o = {
            login: document.getElementById('login').value,
            email: document.getElementById('mail').value
        }
        if (!reg.test(o.login)) a.push('Логин должен содержать только латинские символы и цифры!')
        if (a.length) return setMess(a)
        api.patch('api/auth/reset', o)
            .then((response) => {
                alert('Новый пароль отправлен на почту!')
                setTimeout(() => type('login'), 500)
            })
            .catch((e) => {
				console.log('Error ', e.response?.data);
				let msg = e.response?.data?.message;
				if (!msg) return;
				if (!Array.isArray(msg)) msg = [msg];
				setTimeout(() => setMess(msg), 1000);
			})
    }
}

export default Win