import React, { useCallback, Suspense, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { status } from 'skin/event/status';
import { Cnt } from 'skin/context';
import './style.css';

import Content from 'skin/form/content';
import Head from './head';
import Loading from 'skin/cmp/loading';

import form from 'skin/store/form';
import data from 'skin/store/form/data';

import formAct from './act/form';
import dataAct from './act/data';
import pageAct from './act/page';
import tableAct from './act/table';
import controlAct from './act/control';
import actionAct from './act/action';

import user from 'task/store/action/form';
import story from 'skin/store/form/story';

// Ленивая загрузка
const Func = React.lazy((_) => import('skin/form/func'));

const Form = (props) => {
	const d = JSON.stringify(props.data);
	const code = props.data.code;

	// Пользовательский action
	const userAct = useCallback(
		(obj, form, story, data, d, action) => {
			const fnc = user.s[code] ? user.s[code] : user.s.def;
			return fnc(obj, form, story, data, d, action);
		},
		[code]
	);

	// Стандартный action Формы по key
	const action = useCallback(
		(obj) => {
			const val = userAct(obj, form, story, data, d, action);
			if (val === status.ignore) return status.action;
			switch (obj.type) {
				case 'form':
					formAct(obj, form, data, story, d, action);
					break;
				case 'table':
					tableAct(obj, form, story, data, action);
					break;
				case 'page':
					pageAct(obj, form, data, story, action);
					break;
				case 'control':
					controlAct(obj, form, data, story, action);
					break;
				case 'action':
					actionAct(obj, form, story);
					break;
				case 'data':
					dataAct(obj, data, story, action);
					break;
				default:
				// alert('[Форма] Действие не определено');
			}
			return status.action;
		},
		[userAct, d]
	);
	useEffect(newCode, [d, action]);

	useEffect(() => {
		// Обработка сохранения формы на горячие клавиши ctrl+S
		const key = ['s', 'S', 'ы', 'Ы'];
		const onKeydown = (e) => {
			if (e.ctrlKey && key.includes(e.key)) {
				e.preventDefault();
				action({ type: 'control', action: 'save' });
			}
		};

		document.addEventListener('keydown', onKeydown);

		return () => {
			document.removeEventListener('keydown', onKeydown);
		};
	}, [action]);

	let cls = ['skin-form', 'form'];
	cls = cls.join(' ');

	// Заголовок формы
	const title = form.s?.book?.title
		? form.s.book.title
		: 'Форма <не определена>';

	const func = form.s?.func ?? {};

	// массив страниц
	const pages = form.s?.page?.list ? form.s.page.list : [];
	// кол-во страниц для формы
	const pgCount = form.count;

	const current = story.current;
	// Определяем текущую страницу

	const content = func?.on ? (
		<Suspense fallback={Loading()}>
			<Func func={func} action={action} />
		</Suspense>
	) : (
		<Content />
	);
	return (
		<div
			className={cls}
			data-info-title={'Данные по форме ' + d}
			data-info={`title = ${title}; current = ${JSON.stringify(
				current
			)}; pageCount = ${pgCount}`}
		>
			<Cnt.Provider
				value={{
					action,
					title,
					func,
					pages,
					pgCount,
					current,
				}}
			>
				<Head />
				{content}
			</Cnt.Provider>
		</div>
	);

	// Новый код формы
	function newCode() {
		form.getDef(d, action, story);
	}
};

export default observer(Form);
