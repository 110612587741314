import './style.css'

const Main = props => {
	let cls = [
		'skin-mess-cnt-main',
		'mess-cnt-main'
	]
	cls = cls.join(' ')
	let a = props.text ?? 'Тут будет ваше сообщение'
	if (typeof a === 'string') a = [a]
	return (
		<div className={cls}>
			{a.map((el, i) => <p key={i}>{el}</p>)}
		</div>
	)
}

export default Main
