import './style.css'

const Dlm = props => {
	let cls = [
		'skin-ms-ttl-right-page-dlm',
		'ms-ttl-right-page-dlm'
	]
	cls = cls.join(' ')
	const st ={backgroundImage: 'url(skin/img/ms_page_dlm.svg)'}
	return (
		<div className={cls} style={st}>
			<div></div>
		</div>
	)
}

export default Dlm
