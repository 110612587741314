import api from 'skin/http';
import { message } from 'skin/event';

function prn(obj, ms) {
	const type = obj.type
	const code = obj.code
	const params = obj.params
	const prn = obj.prn ?? false
	const config = {
			method: 'get',
			url: `api/print/${type}/${code}`,
			params
	};
	api(config)
		.then((response) => {
			if (prn) message('Закройте окно предварительного просмотра печати', 'Печать документа', null, '!')
			const pr = window.open()
			pr.document.write(response.data)
			pr.document.close()
			// function prin(type, comment)  {
			// 	console.log('prin', type)
			// 	wait(type, comment)
			// }
			// window.prin = prin
		})

		.catch(console.log);
}


export default prn