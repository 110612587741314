import React from 'react';
import './style.css';
import Barcode from 'react-barcode'

const barcode = ({ s, v }) => {
	let cls = ['skin-barcode', 'barcode'];
	cls = cls.join(' ');
	const sc = v.scale ?? 1
	if(!v.code) return
	const bar = v.code.map((b, i) => {
		return (<div key={i} className={cls} ><Barcode
			value={b}
			width={sc}
			height={sc*50}
			fontSize={sc*10}
			format={"CODE128"}
			displayValue={true}
			/></div>)
	})

	return (
		bar
	)
};

export default barcode;
