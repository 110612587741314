import { observer } from 'mobx-react-lite'
import './style.css'

const Back = ({action, current}) => {
	let cls = [
		'skin-ms-ftr-back',
		'ms-ftr-back',
		'btn'
	]
	cls = cls.join(' ')
	if (!current ) return <div></div>
	const title = 'Вернуться'
	const st = {backgroundImage: 'url(skin/img/ms_back.svg)'}
	return (
		<div className={cls} onClick={back}>
			<p style={st}>{title}</p>
		</div>
	)
	function back() {
		const o = {
			type: 'master',
			action: 'back'
		}
		action(o)
	}
}

export default observer(Back)
