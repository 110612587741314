const data = {
	btn: {
		'ok': 'Понятно',
		'yes': 'Да',
		'no': 'Нет',
		'save': 'Записать'
	},
	type: {
		i: 'info',
		e: 'error',
		'!': 'attention',
		'?': 'question'
	},
	kit: {
		yesno: '[{"type": "yes"}, {"type": "no"}]',
		save: '[{"type": "save"}, {"type": "no"}]',
	}
}
// Готовые наборы кнопки
function kit(key) {
	let a = data.kit[key]
	if (!a) return null
	a = JSON.parse(a)
	return a
}

export default data
export { kit }
