import { useEffect, useState } from 'react';
import './style.css';

const Text = ({ s, v }) => {
	const { _id, name, title = '', label, width, align, style = {}, off } = s;
	let cls = ['skin-text', 'fld-text'];
	cls = cls.join(' ');
	const [val, setVal] = useState();
	useEffect(() => {
		if (!label) return setVal(v ?? '');

		const i = label.indexOf(`%i`);
		const r = label.indexOf(`%r`);
		const a = label.indexOf(`%a`);
		const h = label.indexOf(`%h`);

		if (h !== -1) return href(h);
		if (i !== -1) return insert(i);
		if (r !== -1) return replace(r);
		if (a !== -1) return link(a);
		return setVal(label);

		// вставка - находим %i и вставляем вмето него значения из БД
		function insert(i) {
			if (!v) return setVal(null);
			const res = (
				<p>
					{label.slice(0, i)}{' '}
					<span>
						<b>{v}</b>
					</span>
					{label.slice(i + 2)}
				</p>
			);
			setVal(res);
		}

		// вставка ссылки - находим %a и вставляем вместо него значения из БД
		function link(i) {
			if (!v) return setVal(null);
			const res = (
				<p>
					{label.slice(0, i)} <a href={v}>ссылка</a>
					{label.slice(i + 2)}
				</p>
			);
			setVal(res);
		}
		// вставка ссылки- находим %h и вставляем вместо него текст после него до пробела в виде ссылки
		function href(i) {
			let head = label.slice(0, i) + ' ';
			let tail = label.slice(i + 2);
			let url = tail.match(/\[.*]\S*/)[0];
			tail = tail.replace(url, '');
			let name = url.match(/\[.*]/)[0];
			url = url.replace(name, '');
			name = name.slice(1, name.length - 1);

			const res = (
				<p>
					{head}
					<a href={url} target="_blank" rel="noreferrer">
						{name}
					</a>
					{tail}
				</p>
			);
			setVal(res);
		}

		// замена  находим %r и замещаем все на длинну значения из БД
		function replace(r) {
			if (!v) return setVal(null);
			let tail = label.slice(r + 2);
			tail = tail.length > v.length ? tail.slice(v.length) : '';
			const res = v ? label.slice(0, r) + v + tail : '';
			setVal(res);
		}
	}, [v, label]);

	if (width) {
		style.width = width;
	}
	if (align) {
		style.textAlign = align;
	}
	if (off) style.display = 'none';
	return (
		<div
			className={cls}
			style={style}
			id={_id}
			title={title}
			name={name ?? 'text' + _id}
		>
			{val}
		</div>
	);
};

export default Text;
