import { stackBak } from 'skin/event';
import prn from 'skin/print';

// Действия в таблице
function tableAct(obj, table, story, prp, action) {
	prp = JSON.parse(prp);
	switch (obj.action) {
		case 'callForm':
			table.callForm(prp, obj.info);
			break;
		case 'loadData':
			break;
		case 'initFilter':
			table.getData(prp, story, action);
			break;
		case 'loadDef':
			story.initFilter(table, action);
			break;
		case 'exit':
			exit();
			break;
		case 'row':
			table.rowClick(obj.id, story, prp);
			break;
		case 'reload':
			table.getData(prp, story, action);
			break;
		case 'delete':
			table.del(prp, story, action);
			break;
		case 'add':
			table.add(prp, story, action);
			break;
		case 'print':
			prn(obj)
			break;
		default:
			alert('[Таблица]Действие не определено');
	}
	// Выйти из таблицы
	function exit() {
		story.kill();
		table.close();
		stackBak();
	}
}

export default tableAct;
