import {useState} from 'react';
import './style.css';

const Info = props => {
	let cls = [
		'skin-info',
		'info'
	]
	cls = cls.join(' ')
	const [ok, setOk] = useState('open')
	const st_ok = {backgroundImage: 'url(skin/img/login_' + ok + '.svg)'}
	const cl_ul = ok === 'open' ? 'off' : ''

	return (
		<div className={cls}>
			<ul className={cl_ul}>
				{props.info.map((el, i) => <li key={i}>{el.tit}: {el.val}</li>)}
			</ul>
			<div className='ok' style={st_ok} onClick={okClick}></div>
		</div>
	)
	function okClick() {
		const typ = ok === 'close' ? 'open' : 'close'
		setOk(typ)
	}
}

export default Info
