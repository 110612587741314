import { status } from 'skin/event/status';

const click = (obj, ms, story, data, d, action) => {
	// сохранение по ссылке <a> без предпросмотра
	const href = process.env.REACT_APP_TASK + `/dump/dump.zip`;
	fetch(href)
		.then((res) => res.blob())
		.then((blob) => {
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);

			link.download = 'dump_play.zip';
			link.click();
		});
	return status.action;
};

export default click;
