const data = {
	date: {
		width: '180px',
		align: 'center'
	},
	dt: {
		width: '180px',
		align: 'center'
	},
	number: {
		// width: '80px',
		align: 'right'
	},
	id: {
		width: '205px',
		align: 'center'
	},
	bool: {
		width: '120px',
	}
};

export default data;
