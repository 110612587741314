import Command from './command';
import Footer from './footer';
import Body from './body';
import './style.css';

const Content = () => {
	let cls = ['skin-form-content', 'form-content'];
	cls = cls.join(' ');

	return (
		<div className={cls}>
			<Command />
			<Body />
			<Footer />
		</div>
	);
};

export default Content;
