import {useContext} from 'react'
import {Cnt} from 'skin/context'
import Command from './command'
import "./style.css";

const Head = props => {
	const {action} = useContext(Cnt)
	let cls = ["skin-tbl-head", "tbl-head"];
	cls = cls.join(" ");
	const st = { backgroundImage: "url(skin/img/close.svg)" };
	return (
		<div className={cls}>
			<Command
				title={props.title}
				view={props.view}
			/>
			<span className="ico-r" style={st} onClick={exit}></span>
		</div>
	)
	//
	function exit() {
		const o = {
			type: 'table',
			action: 'exit'
		}
		action(o)
	}
};

export default Head;
