import { makeAutoObservable } from 'mobx';
import api from 'skin/http';

class Data {
	s = {
		// current: 'code',
		// code: {
		// 	values: {
		// 		fld: {},
		// 		table: {},
		// 		img: {},
		// 		file: {},
		// 	},
		// 	errors: {},
		// 	changed: [],
		// }
	};
	constructor(props) {
		makeAutoObservable(this);
	}

	//Создание новой задачи
	wAdd(code, data) {
		const config = {
			method: 'post',
			url: `api/work/add/${code}`,
			data,
		};

		api(config)
			.then(function (res) {})
			.catch(console.log);
	}

	//Проверка готовности задания
	wPing(id) {
		const config = {
			method: 'post',
			url: `api/work/ping`,
			params: { id },
			headers: { nowait: true },
		};

		api(config)
			.then(function (res) {})
			.catch(console.log);
	}
	//Вывод результата задания
	wOut(id, pug, action) {
		if (pug) {
			const url = process.env.REACT_APP_TASK + 'api/work/out/pug?';
			const q = new URLSearchParams({ id }).toString();
			window.open(url + q);
			const o = {
				type: 'master',
				action: 'exit',
			};
			return action(o);
		}
		const url = process.env.REACT_APP_TASK + 'api/work/out?';
		const q = new URLSearchParams({ id }).toString();
		window.open(url + q);
		const o = {
			type: 'master',
			action: 'exit',
		};
		return action(o);
		// 	const config = {
		// 		method: 'post',
		// 		url: `api/work/out`,
		// 		params: {id}
		// 	};
		// 	api(config)
		// 		.then(res =>{
		// 			// console.log('wOut', res.data)
		// 			// if(res.data) {
		// 			// 	let wnd = window.open("about:blank", "");
		// 			// 	wnd.document.write(res.data);
		//     		// 	wnd.document.close();
		// 			// }
		// 		})
		// 		.catch(console.log);
	}
}

const data = new Data();
export default data;
