import { status } from 'skin/event/status'

const signal = (obj, ms, story, data, d, action) => {
	// console.log('[Ячейки - Мастер] событие', obj)
	const sig = data?.s?.values?.fld?.type
	// Невидимые поля
	const txtDelta = document.getElementsByName('txtDelta')?.[0]
	const delta = document.getElementsByName('comment')?.[0]
	const wrapperD = delta?.closest('div.skin-input-base')
	if (txtDelta) txtDelta.style.display = 'none'
	if (wrapperD) wrapperD.style.display = 'none'
	// Включение невидимых полей, когда выбран данный сигнал
	if (sig === 'ПЛК Delta. Модуль неисправен') {
		txtDelta.style.display = 'block'
		wrapperD.style.display = 'block'
	}

	return status.action
}

export default signal
