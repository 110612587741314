import './style.css';
import user from 'skin/store/user';

const Personal = ({ avatar }) => {
	let cls = ['skin-hdr-r-personal', 'hdr-r-personal', 'ico success'];
	cls = cls.join(' ');
	const st = { backgroundImage: avatar, backgroundSize: '40px' };
	return (
		<span className={cls} style={st} title='Личный кабинет' onClick={(_) => user.form() }></span>
	);
};

export default Personal;
