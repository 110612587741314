import space from './space'

const data = {
	/* wait, login, ok */
	type: 'wait',
	shadow: false,
	wait: {on: false, comment: ''},
	dictionary: false,
	message: false,
	space,
	stack: []
}

export default data
