import './style.css';
import SingOut from 'skin/auth/logout';

const Logout = (props) => {
	let cls = ['skin-hdr-r-logout', 'hdr-r-logout', 'ico'];
	cls = cls.join(' ');
	const st = { backgroundImage: 'url(skin/img/logout.svg)' };
	return <span className={cls} style={st} onClick={click} title='Выход'></span>;

	function click() {
		SingOut();
	}
};

export default Logout;
