import React from 'react';
import { addEvent, removeEvent, event } from 'skin/event';
import Login from 'skin/login';
import Signup from 'skin/signup';
import Reset from 'skin/reset';
import Space from 'skin/space';
import Wait from 'skin/wait';
import state from './state';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = state;
		this.event = this.event.bind(this);
	}
	render() {
		return this.page();
	}
	// Определить что показывать изначально
	page() {
		switch (this.state.type) {
			case 'ok':
				return <Space data={this.state} />;
			case 'login':
				return <Login data={this.state.notify} />;
			case 'signup':
				return <Signup data={this.state} />;
			case 'reset':
				return <Reset data={this.state} />;
			default:
				return <Wait />;
		}
	}

	event(e) {
		const o = e.detail;
		event(this, o);
	}

	componentDidMount() {
		addEvent(this);
	}
	componentWillUnmount() {
		removeEvent(this);
	}
}

export default App;
