import { useEffect } from "react";


const Wait = (props) => {
useEffect(() => {
}, [props.data])
	const comment = props.data.comment ? <p>{props.data.comment}</p> : null
	if (!props.data.on) return null;
	return (
		<div className="skin-wait">
			<img src="task/img/wait.gif" alt="Wait" />
			{comment}
		</div>
	);
};

export default Wait;
