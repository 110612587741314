import { observer } from "mobx-react-lite";
import menu from "skin/store/menu";
import Header from "./header";
import Footer from "./footer";
import Crumb from "./crumb";
import Menu from "./menu";
import "./style.css";

const Page = props => {
	let cls = ["skin-page", "page"];
	cls = cls.join(" ");
	let cls_sn = ["sandbox"];
	if (!menu.s.tree) cls_sn.push("full");
	cls_sn = cls_sn.join(" ");
	const mode = props.data.space.type
	return (
		<div className={cls}>
			<Header mode={mode} />
			<Crumb stack={props.data.stack} />
			<article className="main">
				<Menu />
				<div className={cls_sn}>{props.children}</div>
			</article>
			<Footer data={props.data} />
		</div>
	);
};

export default observer(Page);
