import './style.css';

const help = props => {
	let cls = ['skin-hdr-r-help', 'hdr-r-help', 'ico'];
	cls = cls.join(' ');
	const st = { backgroundImage: 'url(skin/img/help.png)' };
	return (
		<span
			className={cls}
			style={st}
			title='Обучающие видео'
			onClick={_ => click()}
		></span>
	)
}

function click() {
	const url = process.env.REACT_APP_TASK + 'api/print/help'
	window.open(url)
}
export default help
