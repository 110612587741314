import React, { Suspense, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import table from 'skin/store/table';
import story from 'skin/store/table/story';
import data from 'skin/store/table/data';
import user from 'task/store/action/table';
import { status } from 'skin/event/status';
import { Cnt } from 'skin/context';
import Head from 'skin/table/head';
import Content from 'skin/table/content';
import Loading from 'skin/cmp/loading';
import filterAct from './act/filter';
import actionAct from './act/action';
import controlAct from './act/control';
import pageAct from './act/page';
import viewAct from './act/view';
import tableAct from './act/table';
import dataAct from './act/data';

import 'skin/table/style.css';

const Func = React.lazy((_) => import('skin/table/func'));

const Table = (props) => {
	const d = JSON.stringify(props.data);
	const code = props.data.code;
	const userAct = useCallback(
		(obj, table, story, d, action) => {
			const fnc = user.s[code] ? user.s[code] : user.s.def;
			return fnc(obj, table, story, d, action);
		},
		[code]
	);
	const page = story.page;
	const vCur = story.view;
	const kCur = story.key;
	const filter = story.filter;
	const pages = table.s?.body?.page ?? 0;
	const action = useCallback(
		(obj) => {
			const val = userAct(obj, table, story, d, action);
			if (val === status.ignore) return status.action;
			switch (obj.type) {
				case 'table':
					tableAct(obj, table, story, d, action);
					break;
				case 'data':
					dataAct(obj, data, action);
					break;
				case 'view':
					viewAct(obj, table, story, action);
					break;
				case 'control':
					controlAct(obj, table, story, d, action);
					break;
				case 'action':
					actionAct(obj, table);
					break;
				case 'filter':
					filterAct(obj, table, story, action);
					break;
				case 'page':
					pageAct(obj, story, table, d, action);
					break;
				default:
				// alert('Действие не определено');
			}
			return status.action;
		},
		[userAct, d]
	);
	useEffect(newCode, [d, action]);
	useEffect(() => {
		const onKeydown = (e) => {
			if (e.keyCode === 13) {
				e.preventDefault();
				action({ type: 'control', action: 'filter' });
			}
		};

		document.addEventListener('keydown', onKeydown);

		return () => {
			document.removeEventListener('keydown', onKeydown);
		};
	}, [action]);
	let cls = ['skin-table', 'table'];
	cls = cls.join(' ');
	const title = table.s?.head?.title ?? '<Не определено>';
	const func = table.s?.func ?? {};
	const view = table.s?.view ?? {};
	const col = table.s?.col ?? [];
	const form = JSON.stringify(table.s?.form)

	const body = table.s?.body ?? {};
	const content = func.on ? (
		<Suspense fallback={Loading()}>
			<Func func={func} action={action} />
		</Suspense>
	) : (
		<Content body={body} view={view} col={col} />
	);
	return (
		<div className={cls} data-info={d} data-info-title="Параметры">
			<Cnt.Provider
				value={{
					code,
					form,
					action,
					page,
					pages,
					view: vCur,
					key: kCur,
					filter,
				}}
			>
				<Head title={title} view={view} func={func} />
				{content}
			</Cnt.Provider>
		</div>
	);

	// Управление событиями

	// Новый код таблицы
	function newCode() {
		const prm = JSON.parse(d);
		story.setCurrent(prm.code);
		table.getDef(d, action);
	}
};

export default observer(Table);
