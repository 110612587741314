import { Polygon, Tooltip, Popup } from 'react-leaflet';
import selCl from '../colors';

const Plg = ({ el, i }) => {
	let color = el.color ? el.color : selCl(i);
	color = { color };
	const tooltip = el.tp ? (
		<Tooltip
			direction={el.tp.direction ?? 'center'}
			offset={el.tp.offset ?? [0, 0]}
			opacity={el.tp.opacity ?? 0.6}
			permanent={el.tp.pernament ?? true}
		>
			{el.tp.name}
		</Tooltip>
	) : null;
	const popup = el.pp ? <Popup>{el.pp}</Popup> : null;

	return (
		<Polygon pathOptions={color} positions={el.geo}>
			{tooltip}
			{popup}
		</Polygon>
	);
};
export default Plg;
