import { dictionary } from 'skin/event';
// Действия
function dataAct(obj, data, action, d) {
	const act = obj.action ?? null;
	switch (act) {
		case 'setInput':
			data.setValue(obj.name, obj.value);
			break;
		case 'setImg':
			data.setImg(obj.name, obj.value);
			break;
		case 'setFile':
			data.setFile(obj.name, obj.value);
			break;
		case 'ping':
			data.ping(obj.work);
			break;
		case 'wAdd':
			data.wAdd(obj.code, obj.data);
			break;
		case 'wPing':
			data.wPing(obj.work, obj.pug);
			break;
		case 'wOut':
			data.wOut(obj.work, obj.pug, action);
			break;
		// Открытие справочника
		case 'dictionary':
			d = JSON.parse(d);
			obj.info = d.info
			obj.filter = d.filter
			dictionary(obj)
			break;
		default:
			alert('[Мастер] [Данные] Действие не определено');
	}
}

export default dataAct;
