import dict from "skin/store/dict";
import { dictionary } from "skin/event";
// Клик на элементе
function click(obj, setFind, func) {
	const type = obj.type ?? "";
	if (type === "folder") {
		dict.setCurrent(obj._id);
		return;
	}
	if (type === "up") {
		dict.up();
		return;
	}
	setFind("");
	dict.setCurrent();
	const val = obj.value 
	if (func) {
		func(val);
	}
	dict.clear()
	dictionary();
}
// Сформировать элемент для возврата из уровня
function upElement(a, cur) {
	if (cur === "" || !a) return null;
	const e = { id: "up", type: "up", list: [". ."] };
	let l = a.length;
	if (l === 0) return e;
	l = a[0].list.length
	e.list = new Array(l).fill("");
	const i = l > 1 ? 1 : 0;
	e.list[i] = ". .";
	return e;
}

export { click, upElement };
