import './style.css';
const Label = ({ label }) => {
    if (label) {
        return <label>{label}</label>;
    }
    if (label === '') {
        return <label>&nbsp;</label>;
    }
    return null;
};
export default Label;
