// Действия со страницами
function pageAct(obj, form, data, story, action) {
	const table = obj.table;

	// Если действие произошло в таблице
	if (table) {
		//Текущее значение
		const current = story.current.table[table].page;
		// Кол-во страниц для таблиц
		const l = data.s.values.table[table].page;

		switch (obj.action) {
			case 'home':
				story.setTblPg(table, 1, l, action);
				break;
			case '-':
				story.setTblPg(table, current - 1, l, action);
				break;
			case '+':
				story.setTblPg(table, current + 1, l, action);
				break;
			case 'end':
				story.setTblPg(table, l, l, action);
				break;
			case '=':
				story.setTblPg(table, obj.page, l, action);
				break;
			default:
				alert('[Форма] [Страницы Таблицы] Действие не определено');
				break;
		}

		return;
	}

	const l = form.count - 1;
	const cur = story.current.page;
	switch (obj.action) {
		case 'home':
			story.setPage(1);
			break;
		case '-':
			story.setPage(cur - 1);
			break;
		case '+':
			story.setPage(cur + 1 > l ? l : cur + 1);
			break;
		case 'end':
			story.setPage(l);
			break;
		case '=':
			story.setPage(obj.page);
			break;
		default:
			alert('[Форма] [Старница] Действие не определено');
	}
}

export default pageAct;
