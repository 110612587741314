const data = {
	all: [
		{
			id: 'equals',
			title: 'Равно',
			query: '$eq'
		},
		{
			id: 'equals_not',
			title: 'Не равно',
			query: '$ne'
		},
		{
			id: 'larger',
			title: 'Больше',
			query: '$gt'
		},
		{
			id: 'larger_eq',
			title: 'Больше или равно',
			query: '$gte'
		},
		{
			id: 'less',
			title: 'Меньше',
			query: '$lt'
		},
		{
			id: 'less_eq',
			title: 'Меньше или равно',
			query: '$lte'
		},
	],
	str: [
		{
			id: 'equals',
			title: 'Равно',
			query: '$eq'
		},
		{
			id: 'equals_not',
			title: 'Не равно',
			query: '$ne'
		},
		{
			id: 'reg',
			title: 'Регулярное выражение',
			query: '$regex'
		},
	]
}

export default data
