import './style.css'

const Title = ({title}) => {
    let cls = ['skin-title', 'title', 'clr-t1']
    cls = cls.join(' ')
    const stl = {
        backgroundImage: 'url(/skin/img/login_pic.png)'
    }
    return (
        <div className={cls}>
            <div style={stl}></div>
            <p>{title}</p>
        </div>
    )
}

export default Title