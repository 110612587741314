import './style.css';

const Message = props => {
	let cls = ['skin-hdr-r-message', 'hdr-r-message', 'ico'];
	cls = cls.join(' ');
	const st = { backgroundImage: 'url(skin/img/list.svg)' };
	return (
		<span
			className={cls}
			style={st}
			title='Сообщения'
			onClick={_ => alert('Сообщения')}
		></span>
	)
}

export default Message
