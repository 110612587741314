import { observer } from 'mobx-react-lite'
import dict from 'skin/store/dict'
import './style.css'

const Head = props => {
	let cls = [
		'skin-dct-cnt-head',
		'dct-cnt-head'
	]
	cls = cls.join(' ')
	const st = {backgroundImage: 'url(skin/img/dct_find.svg)'}
	return (
		<div className={cls}>
			<p>{dict.s.title}</p>
			<div style={st}>
				<input type='text' value={props.find.value} onChange={find} />
			</div>
		</div>
	)
	//
	function find(e) {
		const val = e.target.value
		props.find.setFind(val)
	}
}

export default observer(Head)
