import Itm from './itm'
import './style.css'

const Action = props => {
	let cls = [
		'skin-mess-cnt-action',
		'mess-cnt-action'
	]
	cls = cls.join(' ')
	const act = props.action ?? [{type: 'ok'}]
	return (
		<div className={cls}>
			{act.map((el, i) => <Itm key={i} data={el} item={i} />)}
		</div>
	)
}

export default Action
