import Command from './command';
import './style.css';

const Footer = () => {
	let cls = ['skin-footer', 'clr-t3', 'footer'];
	cls = cls.join(' ');
	const st = { backgroundImage: 'url(skin/img/offer.png)' };
	const link = process.env.REACT_APP_DOC
		? JSON.parse(process.env.REACT_APP_DOC).map((el) => {
				const href = process.env.REACT_APP_TASK + el.file;
				return (
					<a
						key={el.file}
						href={href}
						target="_blank"
						rel="noopener noreferrer"
					>
						<div className="offer" style={st}>
							<p>{el.text}</p>
						</div>
					</a>
				);
		  })
		: [];

	return (
		<footer className={cls}>
			<div className="d-flex jc-between f-grow-1">
				<Command />
				<div className="docs">{link}</div>
				<p>{process.env.REACT_APP_COPYRIGHT}</p>
			</div>
		</footer>
	);
};

export default Footer;
