// import form from "skin/store/form";
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Cnt } from 'skin/context';
import Num from './num';
import './style.css';

const Page = () => {
	const { action, pages, current } = useContext(Cnt);
	const len = pages.length;
	if (!len || len < 3) return null;

	let cls = ['skin-form-page', 'form-page'];
	cls = cls.join(' ');

	const st_home = { backgroundImage: 'url(skin/img/page_home.svg)' };
	const st_up = { backgroundImage: 'url(skin/img/page_up.svg)' };
	const st_dn = { backgroundImage: 'url(skin/img/page_dn.svg)' };
	const st_end = { backgroundImage: 'url(skin/img/page_end.svg)' };
	return (
		<div className={cls}>
			<span
				className="ico page-shift"
				style={st_home}
				onClick={() => set('home')}
			></span>
			<span
				className="ico page-shift"
				style={st_up}
				onClick={() => set('-')}
			></span>

			<Num page={current.page} length={len - 1} setPage={setPage} />

			<span
				className="ico page-shift"
				style={st_dn}
				onClick={() => set('+')}
			></span>
			<span
				className="ico page-shift"
				style={st_end}
				onClick={() => set('end')}
			></span>
		</div>
	);

	function setPage(e) {
		let v = e.target.value;
		if (v > len - 1) v = len - 1;

		const o = {
			type: 'page',
			action: '=',
			page: v,
		};
		action(o);
	}

	function set(act) {
		const o = {
			type: 'page',
			action: act,
		};
		action(o);
	}
};

export default observer(Page);
