import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import dict from 'skin/store/dict';
import { click, upElement } from './fn';
import Row from './row';
import './style.css';

const Body = (props) => {
	const data = props.data ?? {}
	const code = data.code ?? '';
	const info = useMemo(() => data.info ?? [], [data.info]);
	const unauth = data.unauth ?? false;
	useEffect(() => {
		if (unauth) return dict.getData(code, info, unauth);
		dict.getData(code, info);
	}, [code, unauth, info]);
	let cls = ['skin-dct-cnt-main-body', 'dct-cnt-main-body'];
	cls = cls.join(' ');
	const w = dict.s.width ?? {};
	const cur = dict.s.body?.current ?? '';
	const f = props.find.value ?? '';
	const a = f === '' ? dict.list : dict.find(f);
	const el = upElement(a, cur);
	const up = el ? (
		<Row
			key={el.id}
			data={el}
			width={w}
			click={() => click(el, props.find.setFind)}
		/>
	) : null;
	return (
		<table className={cls}>
			<tbody>
				{up}
				{a.map((el) => (
					<Row
						key={el._id}
						func={data.func}
						data={el}
						width={w}
						click={(_) => clk(el)}
					/>
				))}
			</tbody>
		</table>
	);
	// Клик на элементе
	function clk(el) {
		click(el, props.find.setFind, data.func);
	}
};

export default observer(Body);
