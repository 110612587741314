import { status } from 'skin/event/status'
import reload from './reload'

export default function form(obj, ms, story, data, d, action) {
	switch (obj.action) {
		case 'reload':
			return reload(obj, ms, story, data, d, action)
		default:
	}
	return status.action
}
