import Content from "./content";
import "./style.css";

const Dict = props => {
	if (!props.data) return null
	let cls = [
		"skin-dict",
		"dict"
	];
	cls = cls.join(" ");
	return (
		<div className={cls}>
			<div className="dict-shadow"></div>
			<Content data={props.data} />
		</div>
	);
};

export default Dict;
