import { makeAutoObservable, runInAction } from 'mobx';
import { message } from 'skin/event';
import api from 'skin/http';
import msg from 'skin/data/msg';

class Data {
	s = {
		// book,// page,// item,// group,// fld,// text,// table,
		// action,
	};
	constructor(props) {
		makeAutoObservable(this);
	}

	// Вернем количество страниц
	get pageLen() {
		if (!this.s?.page?.list) return 0;
		return this.s.page.list.length;
	}
	// Вернем все страницы
	get pages() {
		if (!this.s?.page?.list) return [];
		return this.s.page.list;
	}

	// Вернем всю страницу по индексу
	getPage(idx) {
		if (!this.s?.page?.list) return {};
		if (!this.s.page.list.length) return {};
		return this.s.page.list[idx];
	}

	// Заголовок мастера
	get bookTitle() {
		return this.s?.book?.title ?? 'Не определено';
	}

	// Заголовок страницы
	getTitle(n) {
		return this.s?.page?.list[n]?.continue ?? '';
	}

	// Завершить работу с функциями
	close() {
		// this.func();
	}

	// Стандартное переход далее
	step(d, story, data, action) {
		const code = story.s.current;
		const page = story.curPage;
		const frwrd = story.nextPage(this.pageLen);
		const value = {};
		// Добавим инфо если оно есть
		if (d && typeof d === 'string') {
			d = JSON.parse(d);
			value.info = d.info;
			value.type = d.type ?? '';
		}

		const fd = data.getAll(this, true);
		fd.append('value', JSON.stringify(value));

		const config = {
			method: 'post',
			url: `api/master/step/${code}/${page}/${frwrd}`,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data: fd,
		};
		const self = this;
		api(config)
			.then((response) => {
				const r = response.data.result;
				// сохраним полученные значения в дату
				data.setData(r, null, action);
				// Переходим на следующую страницу
				const finish = r?.finish ?? null;
				story.goNext(self.pageLen, action, finish);
			})
			.catch(console.log);
	}

	// Станадртная проверка
	check(d, story, data, action) {
		const code = story.s.current;
		const mesTitle = this.s?.book?.title ?? 'Мастер <не определена>';

		if (typeof d === 'string') d = JSON.parse(d);
		const value = {
			info: d.info,
			type: d.type ?? '',
		};

		const page = story.curPage;
		let fd = data.getAll(this, true);
		fd.append('value', JSON.stringify(value));
		const config = {
			method: 'post',
			url: `api/master/check/${code}/${page}`,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data: fd,
			// params: { value, ...d },
		};

		api(config)
			.then(function (response) {
				const res = response.data.result;
				if (!res.ok) {
					if (Object.keys(res.error).length) {
						data.setError(res.error);
						return message(msg.error.fld, mesTitle, null, '!');
					}
					if (res.attention)
						return message(res.attention, mesTitle, null, '!');
				}
				const o = {
					type: 'master',
					action: 'step',
				};
				action(o);
			})
			.catch(console.log);
	}

	// Получение данных структуры с сервера
	getDef(value, action, story, data) {
		const self = this;
		var config = {
			method: 'get',
			url: process.env.REACT_APP_SKIN + 'api/book',
			params: { value },
		};

		api(config)
			.then(function (response) {
				const r = response.data.result;
				runInAction(() => {
					for (let key in r) self.s[key] = r[key];

					// Story Заполняем дефолтными данными
					story.setDef(r.book.code, r.table);
					// Data Заполняем дефолтными данными
					data.setDef(r.book.code);

					// Data Заполняем данные по дефолту и получаем данные с сервера
					const o = {
						type: 'master',
						action: 'loadDef',
					};
					action(o);
				});
			})
			.catch(console.log);
	}
}

const data = new Data();
export default data;
