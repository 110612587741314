import {useContext} from 'react'
import {Cnt} from 'skin/context'
import { observer } from 'mobx-react-lite'
import Page from 'skin/table/page';
import './style.css';

const Footer = props => {
	const {action} = useContext(Cnt)
	let cls = [
		'skin-tbl-footer',
		'tbl-footer'
	]
	cls = cls.join(' ')
	return (
		<div className={cls}>
			<button className='btn primary' onClick={clear}>Очистить</button>
			<Page />
		</div>
	)
	// Очистить фильтры
	function clear() {
		const o = {
			type: 'filter',
			action: 'clear'
		}
		action(o)
	}
}

export default observer(Footer)
