import { observer } from 'mobx-react-lite'
import './style.css'

const Next = ({action, nextTitle}) => {
	let cls = [
		'skin-ms-ftr-next',
		'ms-ftr-next',
		'btn'
	]
	cls = cls.join(' ')
	const title = nextTitle ? nextTitle : 'Продолжить'
	const st = {backgroundImage: 'url(skin/img/ms_next.svg)'}
	return (
		<div className={cls} onClick={next}>
			<p style={st}>{title}</p>
		</div>
	)
	function next() {
		const o = {
			type: 'master',
			action: 'next'
		}
		action(o)
	}
}

export default observer(Next)
