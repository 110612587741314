import Win from './win';
import Links from './links';
import './style.css';
import Notify from 'skin/cmp/notify';

const Login = (props) => {
	let cls = ['skin-login', 'login-box'];
	cls = cls.join(' ');
	const stl = {
		// backgroundImage: 'url(/task/img/pattern.png)',
		backgroundColor: 'grey',
		backgroundSize: 'cover',
	};
	// const stlf1 = {
	// 	backgroundImage: 'url(/task/img/flet.png)',
	// 	backgroundSize: 'cover',
	// };
	// const stlf2 = {
	// 	backgroundImage: 'url(/task/img/f.png)',
	// 	backgroundSize: 'contain',
	// 	backgroundRepeat: 'no-repeat',
	// };
	// const tech = process.env.REACT_APP_TECH
	// 	? JSON.parse(process.env.REACT_APP_TECH)
	// 	: {};
	// const phone = 'tel' + tech.phone;
	return (
		<div className={cls} style={stl}>
			<div className="shadow"></div>
			{/* <div className="flet" style={stlf1}></div> */}
			{/* <div className="f" style={stlf2}></div> */}
			<div className="container">
				<Win />
				{/* <div className="phone">
					<a href={phone}>{tech.phoneText}</a>
				</div> */}
				<Links />
			</div>
			<Notify data={props.data} />
		</div>
	);
};

export default Login;
