import Lnk from './lnk';
import './style.css';

const links = (props) => {
	let cls = ['skin-links', 'links'];
	cls = cls.join(' ');
	let a = <></>;
	const apk = process.env.REACT_APP_APK
		? JSON.parse(process.env.REACT_APP_APK)
		: null;

	if (!apk) return <div className={cls}>{a}</div>;

	a = apk.map((el) => {
		const href = process.env.REACT_APP_TASK + el.href;
		return (
			<Lnk
				key={el.id}
				id={el.id}
				title={el.title}
				href={href}
				pict="/skin/img/demo.png"
			/>
		);
	});
};

export default links;
