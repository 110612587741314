import Back from './back'
import Next from './next'
import './style.css'

const Footer = ({pageCount, action, current, nextTitle}) => {
	let cls = [
		'skin-ms-footer',
		'ms-footer'
	]
	cls = cls.join(' ')
	if (!pageCount) return null
	return (
		<div className={cls}>
			<Back action={action} current={current} />
			<Next action={action} nextTitle={nextTitle} />
		</div>
	)
}

export default Footer
