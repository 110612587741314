import { message } from 'skin/event';
import { kit } from 'skin/data/message';

// Действия в командах

function controlAct(obj, form, data, story, action) {
	const aBtn = kit('yesno');
	let o;
	let mesTitle = form.title;
	let m = data.isChanged(this) ? ['У вас есть несохраненные данные'] : [''];
	if (obj.table) {
		const tbl = form.getTable(obj.table);
		let cur = story.current?.table[obj.table]?.row;
		cur = data.findRow(cur, obj.table);

		mesTitle = tbl.title;

		switch (obj.action) {
			case 'edit':
				message(`Функция не активна`, mesTitle);
				break;
			case 'view':
				message(`Функция не активна`, mesTitle);
				break;
			case 'add':
				o = { type: 'table', action: 'add', table: obj.table };
				aBtn[0].action = (_) => action(o);
				m.push(obj.message ?? 'Вы хотите добавить документ?');
				message(m, mesTitle, aBtn, '?');
				break;
			case 'delete':
				if (!cur) {
					message(`Не выбрана документ для удаления`, mesTitle);
				} else {
					o = {
						type: 'table',
						action: 'delete',
						table: obj.table,
						row: cur,
					};
					aBtn[0].action = (_) => action(o);
					message(
						`Вы хотите удалить документ из таблицы?`,
						mesTitle,
						aBtn,
						'?'
					);
				}
				break;
			default:
				alert('[Форма ] [Команды Таблица] ' + obj.action);
				break;
		}
		return;
	}

	switch (obj.action) {
		case 'action':
			form.func(true);
			break;
		case 'fncUp':
			form.fncUp(obj.data);
			break;
		case 'fncClick':
			form.fncClick(obj.data);
			break;
		case 'add':
			o = { type: 'form', action: 'add' };
			aBtn[0].action = (_) => action(o);
			m.push('Вы хотите добавить документ?');
			message(m, mesTitle, aBtn, '?');
			break;
		case 'delete':
			o = { type: 'form', action: 'delete' };
			aBtn[0].action = (_) => action(o);
			message('Вы хотите удалить весь документ?', mesTitle, aBtn, '?');
			break;
		case 'save':
			o = { type: 'form', action: 'check' };
			aBtn[0].action = (_) => action(o);
			message('Вы хотите сохранить данные?', mesTitle, aBtn, '?');
			break;
		case 'exit':
			o = { type: 'form', action: 'exit' };
			aBtn[0].action = (_) => action(o);
			m.push('Вы хотите закрыть форму?');
			message(m, mesTitle, aBtn, '?');
			break;
		default:
			//alert('[Форма] [Команды] ' + obj.action);
			break;
	}
}

export default controlAct;
