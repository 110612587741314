import { makeAutoObservable, runInAction } from 'mobx';
import api from 'skin/http';
import { body } from './dict_test.js';
import axios from 'axios';
import { wait } from 'skin/event';

class Data {
	s = {
		title: '',
		head: 'code',
		width: {
			0: '50px',
		},
		body,
	};
	constructor(props) {
		makeAutoObservable(this);
	}
	//
	getData(code, info, unauth) {
		const self = this;
		if (!code) return;
		if (unauth) {
			wait(true);
			console.log('waitTrue');
			axios
				.get(`${process.env.REACT_APP_TASK}api/data/dict/unauth/${code}`)
				.then(function (response) {
					const r = response.data.result;
					runInAction(() => {
						for (let key in r) self.s[key] = r[key];
					});
					wait();
				})
				.catch(function (error) {
					console.log(error);
				});
			return;
		}
		const config = {
			method: 'get',
			url: `api/data/dict/${code}`,
			params: { info },
		};
		api(config)
			.then(function (response) {
				const r = response.data.result;
				runInAction(() => {
					for (let key in r) self.s[key] = r[key];
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	// поднять уровень просмотра
	up() {
		const cur = this.s.body.current;
		const o = this.s.body.list.find((el) => el._id === cur);
		this.s.body.current = o.parent;
	}
	// Установить уровень просмотра
	setCurrent(id) {
		id = id ?? '';
		this.s.body.current = id;
	}
	// Очистить данные справочника
	clear() {
		this.s = {
			title: '',
			head: 'code',
			width: {
				0: '50px',
			},
			body,
		};
	}
	// Вернет найденные элементы
	find(val) {
		val = val.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		val = val.split(' ');
		if (!this.s.body) return [];
		const a = this.s.body.list.filter((el) => {
			if (el.type === 'folder') return false;
			return val.every((v) => el.list.join(' ').includes(v));
		});
		return a;
	}
	// Вернет текущий уровень для просмотра
	get list() {
		const cur = this.s?.body?.current;
		if (cur === undefined) return [];
		const a = this.s.body.list.filter((el) => el.parent === cur);
		return a;
	}
}
const data = new Data();
export default data;
