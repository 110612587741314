import './style.css';

const button = ({s, action}) => {
	const {
		_id,
		width,
		label,
		name
	} = s;

	
	let cls = ['cmp-button', 'btn'];


	cls = cls.join(' ');

	return (
		<div className={cls}  onClick={click} id={_id} width={width}>
			<p>{label}</p>
		</div>
	);
	function click() {
		const o = {
			type: 'btn',
			action: 'click',
			code: name
		}
		action(o)
	}
};

export default button;
