import './style.css'

const Btn = ({title, click, type}) => {
    let cls = ['reset-btn']
    cls = cls.join(' ')
    return (
        <div className={cls}>
            <button className='clr-t2 btn' onClick={click} type={type}>
                {title}
            </button>
        </div>
    )
}

export default Btn