import def from 'skin/data/message'
import './style.css'

const Title = props => {
	let cls = [
		'skin-mess-cnt-title',
		'mess-cnt-title'
	]
	cls = cls.join(' ')
	let type = props.data.type ?? 'i'
	if (def.type[type]) type = def.type[type]
	const title = props.data.title ?? 'Информация'
	const st = {backgroundImage: `url(skin/img/mess_t_${type}.svg)`}
	return (
		<div className={cls}>
			<p style={st}>{title}</p>
		</div>
	)
}

export default Title
