import { useContext } from 'react';
import Command from './command';
import './style.css';
import { Cnt } from 'skin/context';
import data from 'skin/store/form/data';

const Title = () => {
	let cls = ['skin-form-head-title', 'form-head-title'];
	cls = cls.join(' ');
	const st = { backgroundImage: 'url(skin/img/close.svg)' };

	const { action } = useContext(Cnt);
	return (
		<div className={cls}>
			<Command />
			<span className="ico-r" style={st} onClick={close}></span>
		</div>
	);
	function close() {
		const t = data.isChanged()? 'control': 'form'
		const o = {
			type: t,
			action: 'exit',
		};
		action(o);
	}
};

export default Title;
