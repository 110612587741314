import { status } from "skin/event/status";
import { space } from "skin/event";

function table(obj, ms, story, data, d, action) {
  const rows = data?.s?.values?.table?.tnk_def?.list;
  const slId = JSON.parse(d)?.info?.[0];

  // Количество колонок у mark
  const cnt = rows.find((el) => el.key === obj.key)?.list[5];
  const mlId = rows.find((el) => el.key === obj.key)?.list[6];

  let o = {
    type: "form",
    title: "Колонки",
    data: {
      code: "tnk_def",
    },
  };
  switch (true) {
    case cnt <= 1: {
      o.data.info = [obj.key, slId, mlId];
      break;
    }
    case cnt > 1: {
      o.data.code = "tnk_ml";
      o.data.info = [obj.key, slId];
      break;
    }
    default:
      return status.action;
  }
  space(o, true);
  return status.ignore;
}
export default table;
