import axios from 'axios';
import { type, wait } from 'skin/event';
import { send } from './fn';

const api = axios.create({
	// Axios Interceptors
	// авто добавление кук
	withCredentials: true,
	// Базавый url
	baseURL: process.env.REACT_APP_TASK,
	timeout: 10000,
});

// Добавляем хедеры в запросы от клиента к серверу
api.interceptors.request.use((config) => {
	// Do something before request is sent
	// ВКЛ Шторки загрузки для запроса
	if (!config.headers.nowait) wait(true);
	delete config.headers.nowait;
	// Акецесс токен из localStorage
	const token = localStorage.getItem('token') ?? null;
	config.headers.Authorization = 'Bearer ' + token;
	config.headers.Task = process.env.REACT_APP_CODE;

	return config;
});

// Перехват 401 ошибки Не авторизованный пользователь
api.interceptors.response.use(
	(response) => {
		wait();
		return response;
	},
	async (error) => {
		// Обрабатываем ошибку
		const original = error.config;
		if (!error.response) {
			wait();
			return error;
		}
		const st = error.response.status;
		// Ошибка авторизации
		if (st === 401) {
			if (error.config && !error.config._isRetry) {
				// Ставим метку чтобы не зациклиться
				original._isRetry = true;
				try {
					// Выполняем рефреш
					const response = await axios.get(
						`${process.env.REACT_APP_TASK}api/auth/refresh`,
						{
							withCredentials: true,
						}
					);
					if (!response) {
						// Ничего не получилось Очищаем токены и переходим на авторизацию
						console.log('Ошибка авторизации! (2) ', error);
						localStorage.clear('token');
						type('login');
						return Promise.reject(error);
					}
					// Сохраняем новый токен и Повторно выполняем запрос
					localStorage.setItem('token', response.data.accessToken);
					return api.request(original);
				} catch (e) {
					console.log('Ошибка авторизации! (3)', e);
					localStorage.clear('token');
					type('login');
				} finally {
					wait();
				}
			} else {
				console.log('Ошибка авторизации! (1)');
				localStorage.clear('token');
				type('login');
			}
		} else send(error, process.env.REACT_APP_TASK);
		wait();
		throw error;
	}
);

export default api;
