import {useContext} from 'react'
import { observer } from 'mobx-react-lite'
import {Cnt} from 'skin/context'
import Item from './item'
import './style.css'
import all from 'skin/data/table/control'

const Control = props => {
	const {action} = useContext(Cnt)
	let cls = [
		'skin-tbl-com-control',
		'tbl-com-control'
	]
	cls = cls.join(' ')
	return (
		<div className={cls}>
			{all.map(el => <Item key={el.id} data={el} click={click} />)}
		</div>
	)
	//
	function click(act) {
		const o = {
			type: 'control',
			action: act
		}
		action(o)
	}
}

export default observer(Control)
