import { useEffect, useState } from 'react';
import './style.css';

const Num = ({ page, length, setPage }) => {
	let cls = ['skin-form-page-num', 'form-page-num'];
	cls = cls.join(' ');

	const [val, setVal] = useState(page ?? 1);
	useEffect(() => {
		if (page) setVal(page);
	}, [page]);

	return (
		<form className={cls}>
			<input
				type="number"
				value={val}
				onChange={setPage}
				min="1"
				max={length}
			/>
			<label>из {length}</label>
		</form>
	);
};

export default Num;
