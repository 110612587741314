import { useContext, useState, useEffect } from 'react';
import {Cnt} from 'skin/context'
import "./style.css";

const Num = props => {
	const {action, page, pages} = useContext(Cnt)
	let cls = ["skin-tbl-page-num", "tbl-page-num"];
	cls = cls.join(" ");
	const [pg, setPg] = useState(page)
	useEffect(_ => setPg(page), [page])
	return (
		<form className={cls} onSubmit={goPage}>
			<input type="number" value={pg + 1} onChange={e => setPg(e.target.value)} />
			<label>из {pages}</label>
		</form>
	);
	// Перейти на нуказанную страницу
	function goPage(e) {
		e.preventDefault();
		const o = {
			type: "page",
			action: "=",
			value: pg
		};
		action(o);
	}
};

export default Num;
