import { useEffect, useState } from 'react';
import Label from './lable';
import './style.css';

const Progress = ({ s, v, action }) => {
	const {
		_id,
		name = 'progress',
		title = '',
		max = 100,
		width = '50px',
		style = {},
		type = 'type',
	} = s;

	// Значение
	const [val, setVal] = useState(v);

	useEffect(() => {
		if (!v) return;
		let val = v.count < 100 ? v.count : 100;
		val = v.end ? 100 : val;
		// val = v.err ? 100 : val
		setVal(val);
		if (val < 100) {
			setTimeout(() => {
				const o = {
					type: 'data',
					action: 'wPing',
					work: v.work,
				};
				action(o);
			}, 1000);
		} else {
			const o = {
				type: 'data',
				action: 'wOut',
				work: v.work,
				pug: v.pug,
			};
			action(o);
		}
	}, [v]);

	let cls = ['skin-progress'];

	cls = cls.join(' ');

	return (
		<div className={cls} style={style}>
			<Label label={s?.label} />
			<div className="progress-container">
				<progress
					id={_id}
					type={type}
					name={name}
					value={val}
					max={max}
					style={{ width: width }}
					title={title}
				/>
			</div>
		</div>
	);
};

export default Progress;
